import React, { useEffect, useRef, useState } from 'react'
import '../location/location.css'
import { Row, Col } from 'reactstrap'
import API_URL from '../../Api/index'
import medan from '../../assets/img/level1-2024/assets-04.png'
import jakarta from '../../assets/img/level1-2024/assets-05.png'
import tanggerang from '../../assets/img/level1-2024/assets-06.png'
import yogyakarta from '../../assets/img/level1-2024/assets-07.png'
import surabaya from '../../assets/img/level1-2024/assets-08.png'
import makassar from '../../assets/img/level1-2024/assets-09.png'
import denpasar from '../../assets/img/level1-2024/assets-10.png'
import wise from '../../assets/img/level1-2024/wise.png'
import ReactGA from 'react-ga4';

const Location = () => {

    useEffect(() => {

    },[]);

    const handleBannerClick = () => {
        // Kirim event ke Google Analytics
        ReactGA.event({
            category: 'Banner',
            action: 'Click',
            label: 'Wise Banner',
        });
    };

    return (
        <section className="location" id='lokasi'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12 col-md-12'>
                    <center><a href='https://wise.com/id/send-money/' target='_blank' onClick={handleBannerClick}><img className='img-fluid-wise judul-wise' src={wise} /></a></center>
                </div>
            </div>
            <div className="location-time container mt-5">
                <h1>Jadwal</h1>
                <div className="location-place">
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                            <img className='img-fluid' src={medan} />
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <h3 className='d-flex justify-content-start'>🗓️ Kamis, 17 Oktober 2024</h3>
                            <h3 className='d-flex justify-content-start  mt-3'>⏱️ 16:00-20:00 WIB</h3>
                            <h4 className='d-flex justify-content-start white mt-3'>🏨 Grand Mercure Medan Angkasa</h4>
                            <h4 className='d-flex justify-content-start white mt-3'>📍 Jl. Sutomo No.1, Perintis, Kec. Medan Tim., Kota Medan, Sumatera Utara 20235</h4>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                            <img className='img-fluid' src={jakarta} />
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex justify-content-start">
                            <div>
                                <h3 className='d-flex justify-content-start'>🗓️ Sabtu, 19 Oktober 2024</h3>
                                <h3 className='d-flex justify-content-start  mt-3'>⏱️ 15:00-19:00 WIB</h3>
                                <h4 className='d-flex justify-content-start white mt-3'>🏨 Mercure Jakarta Simatupang</h4>
                                <h4 className='d-flex justify-content-start white mt-3'>📍 Jl. R.A. Kartini No.18, Lb. Bulus, Jakarta, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12440</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                            <img className='img-fluid' src={yogyakarta} />
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex justify-content-start">
                            <div>
                                <h3 className='d-flex justify-content-start'>🗓️ Rabu, 23 Oktober 2024</h3>
                                <h3 className='d-flex justify-content-start  mt-3'>⏱️ 16:00-20:00 WIB</h3>
                                <h4 className='d-flex justify-content-start white mt-3'>🏨 Royal Ambarukmo Yogyakarta</h4>
                                <h4 className='d-flex justify-content-start white mt-3'>📍 Jl. Laksda Adisucipto No.81, Ambarukmo, Caturtunggal, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                            <img className='img-fluid' src={surabaya} />
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex justify-content-start">
                            <div>
                                <h3 className='d-flex justify-content-start'>🗓️ Jumat, 25 Oktober 2024</h3>
                                <h3 className='d-flex justify-content-start  mt-3'>⏱️ 16:00-20:00 WIB</h3>
                                <h4 className='d-flex justify-content-start white mt-3'>🏨 Swiss-Belinn Tunjungan</h4>
                                <h4 className='d-flex justify-content-start white mt-3'>📍 Jl. Tunjungan No.101, Embong Kaliasin, Kec. Genteng, Surabaya, Jawa Timur 60271</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                            <img className='img-fluid' src={makassar} />
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex justify-content-start">
                            <div>
                                <h3 className='d-flex justify-content-start'>🗓️ Sabtu, 26 Oktober 2024</h3>
                                <h3 className='d-flex justify-content-start  mt-3'>⏱️ 15:00-19:00 WITA</h3>
                                <h4 className='d-flex justify-content-start white mt-3'>🏨 Four Points by Sheraton Makassar</h4>
                                <h4 className='d-flex justify-content-start white mt-3'>📍 Jl. Andi Djemma No.130, Pa'baeng-Baeng, Kec. Tamalate, Kota Makassar, Sulawesi Selatan 90222</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                            <img className='img-fluid' src={denpasar} />
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex justify-content-start">
                            <div>
                                <h3 className='d-flex justify-content-start'>🗓️ Minggu, 27 Oktober 2024</h3>
                                <h3 className='d-flex justify-content-start  mt-3'>⏱️ 15:00 - 19:00 WITA</h3>
                                <h4 className='d-flex justify-content-start white mt-3'>🏨 Swiss-belhotel Rainforest</h4>
                                <h4 className='d-flex justify-content-start white mt-3'>📍 Jl. Sunset Road No.101, Kuta, Kec. Kuta, Kabupaten Badung, Bali 80361</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Location