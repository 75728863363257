import React, { useEffect } from 'react'
import { Routes, Route } from "react-router-dom";
import Index from "./pages/home";
import Ticket from "./pages/ticket";
import Attendance from "./pages/attendance";
import Ambassador from "./pages/ambassador";
import ReactGA from "react-ga4";

export default function App() {
  useEffect(() => {
    ReactGA.initialize('G-KKTWZQN9HS'); // Ganti dengan Measurement ID kamu
    ReactGA.send('pageview');
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/ticket/:id" element={<Ticket />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/ambassador" element={<Ambassador />} />
    </Routes>
  );
}